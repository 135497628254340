import React from 'react'
import { Swiper } from 'antd-mobile-v5'
import './desktopBanner.scss'
import { AdhocbannerEN } from '../../../../utils/imgUrl'
import { AdhocbannerSC } from '../../../../utils/imgUrl'
import { AdhocbannerTC } from '../../../../utils/imgUrl'
import { useIntl } from 'react-intl'
function DesktopBanner(){
  const intl = useIntl();
  const currentLocale = intl.locale;
  const locateUrl={
    en:"en",
    sc:"zh-hans",
    tc:"zh-hant"
  }
  const imgURL=[
    {
      url:currentLocale==="en"?AdhocbannerEN:(currentLocale==="sc"?AdhocbannerSC:AdhocbannerTC),
      title:'Title1',
      description:'Description1',
    }
  ]
  const handleClick=()=>{
    if(currentLocale==="sc"){
      window.location.href="https://www.sunlife.com.hk/zh-hans/about-us/tools-resources/eadvice-service/"
    }else{
      window.location.href=`https://www.sunlife.com.hk/${locateUrl[currentLocale]}/client-support/eadvice-service/`
    }
  }
  const items = imgURL.map((item, index) => (
    <Swiper.Item key={index}>
      <div
        onClick={()=>{handleClick()}}
        style={{
          width:'100%',
          height:'26.67vw',
        }}
      >
        <img 
          src={item.url}
          alt=""
          className='imgItem'
          style={{
            height:'26.67vw',
            // 'object-fit': 'cover'
          }}
        />
      </div>
    </Swiper.Item>
  ))
  return (
    <div className='deskBanner'>
      <Swiper
        loop
        autoplay
      >
        {items}
      </Swiper>
    </div>
  )
}
export default DesktopBanner;
