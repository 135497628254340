import React, { useEffect ,useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './header.scss';
import { LogoTop } from '../../../utils/imgUrl';
import { Button } from 'antd-mobile-v2'
import { FormattedMessage,useIntl } from 'react-intl';
function Header({ switchLocale }) {
  const [isWechatBrowser, setIsWechatBrowser] = useState(true);
  const navigate = useNavigate();
  const intl = useIntl();
  const currentLocale = intl.locale;
  const locateUrl={
    en:"en",
    sc:"zh-hans",
    tc:"zh-hant"
  }
  const locateUrl2={
    en:"en",
    sc:"zh-cn",
    tc:"zh-hk"
  }
  useEffect(() => {
    //获取当前浏览器的 User Agent
    const userAgent = navigator.userAgent.toLowerCase();
    //检测是否在微信浏览器中
    const isWechat = /micromessenger/.test(userAgent);
    setIsWechatBrowser(isWechat);
  }, [isWechatBrowser]);
  const handleClick=()=>{
    if(isWechatBrowser){
      const pageName='Login'
      navigate(`/redirectPage/${pageName}/Lg/${currentLocale}`);
    }else{
      window.location.href =`https://mysunlife.sunlife.com.hk/ClientAppWeb/#/login?locale=${locateUrl2[currentLocale]}`
    }
  }
  return (
    <div className='bgImg'>
        <img src={LogoTop} alt="" />
        {currentLocale==="sc"
          ?(<div className='btnBox'>
            <Button onClick={()=>{
              switchLocale('en')
            }}>EN</Button>
            <span>|</span>
            <Button onClick={()=>{
              switchLocale('tc')
            }}>繁</Button>
          </div>
          ):null
        }
        {currentLocale==="en"
          ?(<div className='btnBox'>
            <Button onClick={()=>{
              switchLocale('tc')
            }}>繁</Button>
            <span>|</span>
            <Button onClick={()=>{
              switchLocale('sc')
            }}>简</Button>
          </div>
          ):null
        }
        {currentLocale==="tc"
          ?(<div className='btnBox'>
            <Button onClick={()=>{
              switchLocale('en')
            }}>EN</Button>
            <span>|</span>
            <Button onClick={()=>{
              switchLocale('sc')
            }}>简</Button>
          </div>
          ):null
        }
        <div 
          className='changeLg'
          onClick={()=>{
            handleClick()
          }}
        >
          <Button>
            <FormattedMessage id="signIn"></FormattedMessage>
          </Button>
        </div>
      </div>    
  );
}
export default Header;