import React, { useEffect, useCallback, useState } from 'react';
import Header from './header.jsx'
// import Footer from './footer.jsx'
import { TabBar } from 'antd-mobile-v5';
import { Home, TBOnlineTools, TBNotice, MyAccount, HomeActive, TBOnlineToolsActive, TBNoticeActive, MyAccountActive, TBSunWallet,TBSunWalletActive } from '../../../utils/imgUrl.js';
import MainContent from './mainContent/index.jsx'
import './index.scss'
import { FormattedMessage, useIntl } from 'react-intl';
import {
  useNavigate
} from 'react-router-dom'
function Bottom(){
  const tabBarList = [
    {
      key: 'home',
      selectedIcon: HomeActive,
      icon: Home
    },
    {
      key: 'TBOnlineTools',
      icon: TBOnlineTools,
      selectedIcon: TBOnlineToolsActive,
    },
    {
      key: 'TBSunWallet',
      icon: TBSunWallet,
      selectedIcon: TBSunWalletActive,
    },
    {
      key: 'TBNotifications',
      icon: TBNotice,
      selectedIcon: TBNoticeActive,
    },
    {
      key: 'myAccount',
      icon: MyAccount,
      selectedIcon: MyAccountActive
    }
  ]
  const [activeKey, setActiveKey] = useState('home');
  const [isWechatBrowser, setIsWechatBrowser] = useState(true);
  useEffect(() => {
    //获取当前浏览器的 User Agent
    const userAgent = navigator.userAgent.toLowerCase();
    //检测是否在微信浏览器中
    const isWechat = /micromessenger/.test(userAgent);
    setIsWechatBrowser(isWechat);
  }, [isWechatBrowser]);
  const navigate = useNavigate()
  const intl = useIntl();
  const currentLocale = intl.locale;
  const locateUrl={
    en:"en",
    sc:"zh-hans",
    tc:"zh-hant"
  }
  const locateUrl2={
    en:"en",
    sc:"zh-cn",
    tc:"zh-hk"
  }
  const setRouteActive = (value) => {
    if(value==='TBOnlineTools'){
      // navigate(`/moreService/${currentLocale}'`)
      if(locateUrl==="sc"){
        window.location.href=`https://www.sunlife.com.hk/zh-hant/about-us/tools-resources/`
      }else{
        window.location.href=`https://www.sunlife.com.hk/${locateUrl[currentLocale]}/about-us/tools-resources/`
      }
    }else if(value==='TBNotifications'||value==='myAccount'||value==='TBSunWallet'){
      // window.location.href ='https://mysunlife.sunlife.com.hk/ClientAppWeb/#/login'
      if(isWechatBrowser){
        const pageName='Login'
        if(value==='TBNotifications'){
          navigate(`/redirectPage/${pageName}/notification/${currentLocale}`)
        }else if(value==='myAccount'){
          navigate(`/redirectPage/${pageName}/dashboard/${currentLocale}`)
        }else if(value==='TBSunWallet'){
          navigate(`/redirectPage/${pageName}/sun-wallet/${currentLocale}`)
        }
      }else{
        if(value==='TBNotifications'){
          window.location.href=`https://mysunlife.sunlife.com.hk/ClientAppWeb/#/login?redirect_url=/notification&locale=${locateUrl2[currentLocale]}`
        }else if(value==='myAccount'){
          window.location.href=`https://mysunlife.sunlife.com.hk/ClientAppWeb/#/login?redirect_url=/dashboard&locale=${locateUrl2[currentLocale]}`
        }else if(value==='TBSunWallet'){
          window.location.href=`https://mysunlife.sunlife.com.hk/ClientAppWeb/#/login?redirect_url=/sun-wallet&locale=${locateUrl2[currentLocale]}`
        }
        // window.location.href =(value==='TBNotifications')
        // ?`https://mysunlife.sunlife.com.hk/ClientAppWeb/#/login?redirect_url=/notification&locale=${locateUrl2[currentLocale]}`
        // :`https://mysunlife.sunlife.com.hk/ClientAppWeb/#/login?redirect_url=/dashboard&locale=${locateUrl2[currentLocale]}`
      }
    }
    
  }
  return <div className="Footer">
    <div style={{ borderTop: '1px solid #ccc' }}>
      <TabBar
        activeKey={activeKey}
        onChange={(activeKey) => {
          setActiveKey(activeKey)
          setRouteActive(activeKey)
        }}
      >
        {tabBarList.map(item => (
          <TabBar.Item
            title={<FormattedMessage id={item.key}></FormattedMessage>}
            key={item.key}
            icon={
              <div
                style={{
                  width: '27px',
                  height: '27px',
                  background: `url(${activeKey === item.key ? item.selectedIcon : item.icon}) center center / 27px 27px no-repeat`,
                }}
              />
            }
          >
          </TabBar.Item>
        ))}
      </TabBar>
    </div>
  </div>
}
function HomePage({ switchLocale,syncLocale }) {
  const intl = useIntl();
  const setPageTitle = useCallback(() => {
    // 设置页面标题
    document.title = intl.formatMessage({ id: "PromptTitle" });
  }, [intl]);
  useEffect(() => {
    setPageTitle();
  }, [setPageTitle]);
  return (
    <div className="HomePage">
        <Header switchLocale={switchLocale} />
        <MainContent />
        <Bottom />
    </div>
  );
}

export default HomePage;
