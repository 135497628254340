import React from 'react';
import MyTabBar from '../../../common/myTabBar.jsx'
import './quicklink.scss'
//配置图标文件
import {
  PremiumAllocation,
  RequestStatus,
  EditProfile,
  // PayoutMethodV2,
  ValueWithdrawal,
  MakeAClaim,
  More,
  MyPolicies,
  FundSwitch,
} from '../../../../utils/imgUrl'
function QuickLink() {
  const tabs = [
    {
      key: 'myPolicies',
      handleName:'handleBrowser',
      url: MyPolicies,
      route: "/policy-list",
      alt: ''

    },
    {
      key: 'fundSwitch',
      handleName:'handleBrowser',
      url: FundSwitch,
      route: "/fund-switch-policy-list",
      alt: ''

    },
    {
      key: 'premiumAllocation',
      handleName:'handleBrowser',
      url: PremiumAllocation,
      route:"/premium-allocation-policy-list",
      alt: ''

    },
    {
      key: 'editProfile',
      handleName:'handleBrowser',
      route:"/profile",
      url: EditProfile,
      alt: ''
    },
    {
      key: 'makeAClaim',
      handleName:'handleBrowser',
      url: MakeAClaim,
      route:"/make-claim",
      alt: ''

    },
    {
      key: 'requestStatus',
      handleName:'handleBrowser',
      url: RequestStatus,
      route:"/policy-detail",
      alt: ''

    },
    // {
    //   key: 'payoutMethod',
    //   handleName:'handleBrowser',
    //   url: PayoutMethodV2,
    //   route:"/payout-method",
    //   alt: ''

    // },
    {
      key: 'valueWithdrawal',
      handleName:'handleBrowser',
      url: ValueWithdrawal,
      route:"/value-withdrawal",
      alt: ''

    },
    {
      key: 'more',
      // title: '其他服务',
      url: More,
      handleName:'handleClickMore',
      alt: ''
    }
  ]
  const pageName = 'quickLink'
  return (
    <MyTabBar
      tabs={tabs}
      pageName={pageName}
    />
  );
}

export default QuickLink;