import React from 'react'
import { Swiper } from 'antd-mobile-v5'
import {ImgFrame1,ImgFrame2,ImgFrame3,BgTop2} from '../../../../utils/imgUrl';
import { FormattedMessage,useIntl } from 'react-intl';
import './imgFrame.scss'
function ImgFrame(){
  const intl = useIntl();
  const currentLocale = intl.locale;
  const locateUrl={
    en:"en",
    sc:"zh-hans",
    tc:"zh-hant"
  }
  const imgs=[
    {
      productName:"imgFrame1.productName",
      txtName:"imgFrame1.txt",
      imgUrl:ImgFrame1,
      url:`https://www.sunlife.com.hk/${locateUrl[currentLocale]}/insurance/savings-and-life/sunjoy-global/`
    },
    {
      productName:"imgFrame2.productName",
      txtName:"imgFrame2.txt",
      imgUrl:ImgFrame2,
      url:`https://www.sunlife.com.hk/${locateUrl[currentLocale]}/insurance/savings-and-life/sungift-global/`
    },
    {
      productName:"imgFrame3.productName",
      txtName:"imgFrame3.txt",
      imgUrl:ImgFrame3,
      url:`https://www.sunlife.com.hk/${locateUrl[currentLocale]}/client-support/eadvice-service/`
    }]
  const items = imgs.map((item, index) => (
    <Swiper.Item key={index}>
      <div
        className='swItem'
        onClick={() => {
          window.location.href=item.url
        }}
      >
        <img className='imgItem' src={item.imgUrl} alt="" />
        <div className='imgtxt'>
          <div><FormattedMessage id={item.txtName}></FormattedMessage></div>
          <div className='productName'><FormattedMessage id={item.productName}></FormattedMessage></div>
        </div>
      </div>
    </Swiper.Item>
  ))
  // const ref = useRef<SwiperRef>(null)
  return (
    <div 
      className='imgFrame'
      id='imgFrame'
    >
      <Swiper
            loop
            autoplay
            autoplayInterval='5000'
            indicatorProps={{
              color: '#666666',
            }}
            style={{
              '--track-padding': ' 0 0 10px',
            }}
          >
            {items}
      </Swiper>
      <img className='bg2' src={BgTop2} alt="" />
      {/* <span className='msg'>点解年纪大就买唔到保险？</span> */}
    </div>
  )
}


export default ImgFrame;