import React,{useState,useEffect} from 'react';
import { HashRouter  as Router, Route, Routes,Navigate } from 'react-router-dom';
import HomePage from './components/page/homepage/index.jsx';
import MoreService from './components/page/moreService.jsx';
import Browser from './components/page/browserPage.jsx';
import RedirectPage from './components/page/RedirectPage.jsx';

import { IntlProvider } from 'react-intl';
import { default as messages_EN } from '../src/locales/messages_EN.json';
import { default as messages_SC } from '../src/locales/messages_SC.json';
import { default as messages_TC } from '../src/locales/messages_TC.json';

function App() {
  // const locateUrl={
  //   "en":"en",
  //   "sc":"sc",
  //   "tc":"tc",
  // }
  const currentHref=window.location.href.split('/')
  const lg=currentHref[currentHref.length-1]
  const [loading, setLoading] = useState(true);
  const [locale, setLocale] = useState( lg ||'sc');
  useEffect(() => {
    // 在组件加载完毕后设置 loading 为 false
    setLoading(false);
  }, []);
  const messages = {
    en: messages_EN,
    sc: messages_SC,
    tc: messages_TC
  };
  const handleSwitchLocate = (newLocale) => {
    window.location.hash = `/${newLocale}`;
    setLocale(newLocale);
  };

  return (
    <IntlProvider locale={locale} messages={messages[locale]} defaultLocale='en'>
      {loading?(
        <div className="loading">Loading...</div>
      ):(
        <Router>
          <div className="App">
            <Routes>
              <Route path="/" element={<Navigate to={`${lg}`||"sc"} />} />
              <Route path="/:locate" element={<HomePage switchLocale={handleSwitchLocate}/>} />
              <Route path="/moreService/:locate" element={<MoreService />} />
              <Route path="/browser/:locate" element={<Browser />} />
              <Route 
                path="/redirectPage/:pageName/:route/:locate" 
                element={
                  <RedirectPage 
                    switchLocale={handleSwitchLocate}
                  />
                } />
            </Routes>
          </div>
        </Router>
      )} 
      
    </IntlProvider>
  );
}

export default App;
// import React, { useState, useEffect } from 'react';
// import { HashRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
// import HomePage from './components/page/homepage/index.jsx';
// import MoreService from './components/page/moreService.jsx';
// import Browser from './components/page/browserPage.jsx';
// import RedirectPage from './components/page/RedirectPage.jsx';

// import { IntlProvider } from 'react-intl';
// import { default as messages_EN } from '../src/locales/messages_EN.json';
// import { default as messages_SC } from '../src/locales/messages_SC.json';
// import { default as messages_TC } from '../src/locales/messages_TC.json';

// function App() {
//   const [loading, setLoading] = useState(true);
//   const [locale, setLocale] = useState(getLocaleFromHash() || 'sc');

//   useEffect(() => {
//     // 在组件加载完毕后设置 loading 为 false
//     setLoading(false);
//   }, []);

//   useEffect(() => {
//     // 监听 hash 变化，如果 URL 变化则更新语言
//     const handleHashChange = () => {
//       const newLocale = getLocaleFromHash();
//       if (newLocale) {
//         setLocale(newLocale);
//       }
//     };

//     window.addEventListener('hashchange', handleHashChange);

//     return () => {
//       window.removeEventListener('hashchange', handleHashChange);
//     };
//   }, []);

//   const messages = {
//     en: messages_EN,
//     sc: messages_SC,
//     tc: messages_TC
//   };

//   const handleSwitchLocate = (newLocale) => {
//     window.location.hash = `/${newLocale}`;
//     setLocale(newLocale);
//   };

//   function getLocaleFromHash() {
//     const hash = window.location.hash;
//     const match = hash.match(/^#\/(\w{2})/);
//     return match ? match[1] : null;
//   }

//   return (
//     <IntlProvider locale={locale} messages={messages[locale]} >
//       {loading ? (
//         <div className="loading">Loading...</div>
//       ) : (
//         <Router>
//           <div className="App">
//             <Routes>
//               <Route path="/" element={<Navigate to={`${locale}`} />} />
//               <Route path="/:locate" element={<HomePage switchLocale={handleSwitchLocate} />} />
//               <Route path="/moreService/:locate" element={<MoreService />} />
//               <Route path="/browser/:locate" element={<Browser />} />
//               <Route 
//                 path="/redirectPage/:pageName/:route/:locate" 
//                 element={
//                   <RedirectPage 
//                     switchLocale={handleSwitchLocate}
//                   />
//                 } />
//             </Routes>
//           </div>
//         </Router>
//       )} 
//     </IntlProvider>
//   );
// }

// export default App;

