import React,{ useEffect,useCallback } from 'react';
import { useParams } from 'react-router-dom';
import {
  UpperRight,
  Safari,
  Chrome,
  Edge,
  AppLOGO,
  Arrow

} from '../../utils/imgUrl'
import './browserPage.scss'
import { FormattedMessage,useIntl  } from 'react-intl';
function Browser() {
  const { pageName } = useParams();
  const intl = useIntl();
  const setPageTitle = useCallback(() => {
    // 设置页面标题多语言切换
    document.title = intl.formatMessage({ id: "PromptTitle" });
  }, [intl]);
  useEffect(() => {
    setPageTitle();
  }, [setPageTitle]);

  //判断是否为替换url的操作
  //是：当前页面打开url
  //不是：从微信浏览器跳默认浏览器
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      switch(pageName){
        case 'TarBar':
        window.location.href = "https://mysunlife.sunlife.com.hk/ClientAppWeb/#/login";
        break;
        case 'learnMore':
        window.location.href = 'https://www.sunlife.com.hk/en/investments/mpf-and-orso-scheme/Digitalizing-your-MPF-journey/';
        break;
      }      
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [pageName]);
  return (
      <div className="Browser">
        <div className="step">
          <div className='instruct'><FormattedMessage id='instruct1.title'></FormattedMessage></div>
          <img src={UpperRight} alt="" />
          <img className='instruct'src={Arrow} alt="" />
        </div>
        <div className="step">
          <div className='instruct'><FormattedMessage id='instruct2.title'></FormattedMessage></div>
          <div ><FormattedMessage id='instruct2.text'></FormattedMessage></div>
          <div className="imgGroup">
            <img src={Safari} alt="" />
            <img src={Chrome} alt="" />
            <img src={Edge} alt="" />
          </div>
          <img src={Arrow} alt="" />
        </div>
        <div className="step">
          <div className='instruct'><FormattedMessage id='instruct3.title'></FormattedMessage></div>
          <div className='underline'>https://mysunlife.sunlife.com.hk/ClientAppWeb</div>
          <img src={AppLOGO} alt="" />
        </div>
      </div>
  );
}

export default Browser;