import React,{useEffect,useState} from 'react';
import './myTabBar.scss'
import { FormattedMessage,useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

function MyTabBar({tabs,pageName}){
  const intl = useIntl();
  const currentLocale = intl.locale;
  const locateUrl={
    en:"en",
    sc:"zh-hans",
    tc:"zh-hant"
  }
  const locateUrl2={
    en:"en",
    sc:"zh-cn",
    tc:"zh-hk"
  }
  const navigate  = useNavigate();
  const [isWechatBrowser, setIsWechatBrowser] = useState(false);
  useEffect(() => {
    // 获取当前浏览器的 User Agent
    const userAgent = navigator.userAgent.toLowerCase();
    // 检测是否在微信浏览器中
    const isWechat = /micromessenger/.test(userAgent);
    setIsWechatBrowser(isWechat);
  }, []);
  const openURL=(val)=>{
    const url = val;
    window.location.href = url;
  }
  //点击事件分发入口
  const handleClick=(handleName,pageName,module,route)=>{
    switch (handleName) {
      //使用资源这几个工具没有简中页面，需要特殊处理。
      //简中->繁中
      case 'find-a-form':
      case 'exchange-rates':
      case 'personal-information-collection':
      case 'faq':
        if(currentLocale==="sc"){
          openURL(`https://www.sunlife.com.hk/zh-hant/${module}/${handleName}/`)
        }else{
          openURL(`https://www.sunlife.com.hk/${locateUrl[currentLocale]}/${module}/${handleName}/`)
        }
      case 'handleClickMore':
        navigate(`/moreService/${currentLocale}`);
      break;
      case 'handleBrowser':
        if(isWechatBrowser){
          const pageName='TarBar'
          navigate(`/redirectPage/${pageName}${route}/${currentLocale}`);
        }else{
          openURL(`https://mysunlife.sunlife.com.hk/ClientAppWeb/#/login?redirect_url=${route}&locale=${locateUrl2[currentLocale]}`)
        }
      break;
      case 'individual-insurance-claims':
        if(currentLocale==="sc"){
          openURL(`https://www.sunlife.com.hk/zh-hant/${pageName}/${handleName}/`)
        }else{
          openURL(`https://www.sunlife.com.hk/${locateUrl[currentLocale]}/${pageName}/${handleName}/`)
        }
      break;
      default:
        openURL(`https://www.sunlife.com.hk/${locateUrl[currentLocale]}/${module}/${handleName}/`)
    }
    
  };
  return (
    <div className='myTabBarPage'>
      <div 
          className={[pageName,'myTabBar'].join(' ')}
      >
      {tabs.map((item, index) => (
        <div 
          className='tarBarItem'
          key={`${pageName}${item.key}`}>
          <img onClick={()=>{handleClick(item.handleName,pageName,item.module,item.route)}} src={item.url} alt={item.alt} />
          <div className='tarBarText'>
            <FormattedMessage id={item.key}></FormattedMessage>
              {/* <span>{item.title}</span> */}
          </div>
        </div> 
      ))}
      </div>
    </div>
  );
}

export default MyTabBar;