import React from 'react';
import './imgDisplay.scss'
import { FormattedMessage,useIntl } from 'react-intl';
// import { useNavigate } from 'react-router-dom';
function ImgDisplay ({ imgList, pageName, hasDescription }){
    const intl = useIntl();
    const currentLocale = intl.locale;
    const locateUrl={
        en:"en",
        sc:"zh-hans",
        tc:"zh-hant"
    }
    // const navigate  = useNavigate();
    const openURL=(val)=>{
        const url = val;
        window.location.href = url;
    }
    const handleClick=(handleName,pageName,section)=>{
      //点击事件分发入口
      switch (handleName) {
        case 'latestNews':
            openURL(`https://www.sunlife.com.hk/${locateUrl[currentLocale]}/about-us/who-we-are/latest-events-video/`)
        break;
        case 'wehealth-prestige':
        case 'wehealth-preferred':
        case 'wehealth-plus':
        case 'wehealth':
            openURL(`https://www.sunlife.com.hk/${locateUrl[currentLocale]}/insurance/${section}/${handleName}/`)
        break;
        case 'LearnMore':
            openURL(`https://www.sunlife.com.hk/${locateUrl[currentLocale]}/insurance/${pageName}/`)
        break;
        default:
            openURL(`https://www.sunlife.com.hk/${locateUrl[currentLocale]}/insurance/${pageName}/${handleName}/`)
        // 可以有多个 case 分支
      }  
    };
    return (
        <div
            className={[pageName, 'imgList'].join(' ')}
        >
            {imgList.map((item, index) => (
                <div
                    className='imgListItem'
                    key={`${pageName}${item.title}`}
                >
                    <img 
                        src={item.url} 
                        alt={item.alt}
                        onClick={()=>{handleClick(item.handleName,pageName,item.section)}}
                    />
                    <div className='itemTitle'><FormattedMessage id={`${pageName}.${item.title}`}></FormattedMessage></div>
                    {hasDescription?(<div className='itemDescription'><FormattedMessage id={`${pageName}.${item.description}`}></FormattedMessage></div>):null}
                </div>
            ))}
        </div>
    );
}
export default ImgDisplay;