import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.jsx';
import './style/common.css';
import FastClick from 'fastclick';
// 在页面加载完成后初始化 FastClick
document.addEventListener('DOMContentLoaded', function() {
  FastClick.attach(document.body);
}, false);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App/>
  </React.StrictMode>
);

