import React, { useEffect, useState } from 'react';
import './learnMore.scss'
import { LogoOrange } from '../../../../utils/imgUrl'
import { FormattedMessage,useIntl } from 'react-intl';
import { isAndroid, isIOS } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
function LearnMore() {
  const intl = useIntl();
  const currentLocale = intl.locale;
  const locateUrl={
    en:"en",
    sc:"zh-hans",
    tc:"zh-hant"
  }
  const appDownload = () => { 
    const appUrlScheme = 'bestserve://'; // 替换为你的应用程序的 URL Scheme
    const linkToUrl = `https://www.sunlife.com.hk/${locateUrl[currentLocale]}/investments/mpf-and-orso-scheme/Digitalizing-your-MPF-journey/`; // 浏览器自动跳转的url
    function failed() { 
      window.location.href = linkToUrl 
    } 
    function transfer(cb) { 
      window.location.href = appUrlScheme; 
      const initialTime = new Date(); 
      let counter = 0; 
      let waitTime = 0; 
      const checkOpen = setInterval(() => { 
        counter++; 
        waitTime = new Date() - initialTime; 
        if (waitTime > 3500) { 
          clearInterval(checkOpen); 
          cb(); 
        } 
        if (counter < 1000) { 
          return; }; 
        } 
      ,20); 
      document.addEventListener('visibilitychange', () => { 
        const isHidden = document.hidden; 
        if (isHidden) { 
          clearInterval(checkOpen); 
        } 
      }); 
    } 
    transfer(failed); 
  }
  const navigate = useNavigate();
  const [isWechatBrowser, setIsWechatBrowser] = useState(false);
  useEffect(() => {
    // 获取当前浏览器的 User Agent
    const userAgent = navigator.userAgent.toLowerCase();
    // 检测是否在微信浏览器中
    const isWechat = /micromessenger/.test(userAgent);
    setIsWechatBrowser(isWechat);
  }, []);
  const handleButtonClick = () => {
    if (isWechatBrowser) {
      const pageName = 'learnMore'
      navigate(`/redirectPage/${pageName}/LM/${currentLocale}`);
    } else {
      // openAppOrUrl();
      openUrl();
    }
  }
  const openUrl = () =>{
    const linkToUrl = currentLocale==="en"
    ?"https://online.cspension.com.hk/hkpensionweb/#/login?source=corporateHK&vgnLocale=en_CA&WT.ac=zh-hk:web:slf_internal:hp_signin_modal:rainbowmpf"
    :"https://online.cspension.com.hk/hkpensionweb/#/login?source=corporateHK&vgnLocale=zh_TW&WT.ac=zh-hk:web:slf_internal:hp_signin_modal:rainbowmpf"; // 浏览器自动跳转的url
    window.location.href=linkToUrl
  }
  // const openAppIOS = () => {
  //   // 替换成你的应用程序的 Universal Link 或者 Custom URL Scheme
  //   const appUrl = "bestserve://"; 
  //   // const downloadUrl = "https://yourapp.com/download"; // 应用程序下载网站链接
  //   const downloadUrl = currentLocale==="en"
  //   ?"https://online.cspension.com.hk/hkpensionweb/#/login?source=corporateHK&vgnLocale=en_CA&WT.ac=zh-hk:web:slf_internal:hp_signin_modal:rainbowmpf"
  //   :"https://online.cspension.com.hk/hkpensionweb/#/login?source=corporateHK&vgnLocale=zh_TW&WT.ac=zh-hk:web:slf_internal:hp_signin_modal:rainbowmpf"; // 浏览器自动跳转的url

    
  //   // 创建一个隐藏的 iframe 元素来尝试打开应用程序链接
  //   const iframe = document.createElement('iframe');
  //   iframe.style.display = 'none';
  //   iframe.src = appUrl;
  //   document.body.appendChild(iframe);
  
  //   // 设置一个超时，在超时后打开应用程序下载网站链接
  //   const timeout = setTimeout(() => {
  //     window.location.href = downloadUrl;
  //   }, 2000); // 2 秒后超时
  
  //   // 当应用程序链接成功打开时清除超时
  //   window.addEventListener('pagehide', () => {
  //     clearTimeout(timeout);
  //   });
  // };
  
  // const openAppOrUrl = () => {
  //   const appUrlScheme = 'bestserve://'; // 替换为你的应用程序的 URL Scheme
  //   //暂时将MPF简中页面替换为繁中页面处理
  //   const linkToUrl = currentLocale==="en"
  //   ?"https://online.cspension.com.hk/hkpensionweb/#/login?source=corporateHK&vgnLocale=en_CA&WT.ac=zh-hk:web:slf_internal:hp_signin_modal:rainbowmpf"
  //   :"https://online.cspension.com.hk/hkpensionweb/#/login?source=corporateHK&vgnLocale=zh_TW&WT.ac=zh-hk:web:slf_internal:hp_signin_modal:rainbowmpf"; // 浏览器自动跳转的url
  //   const playStoreUrl = 'com.hk.sunlife.pension.mobile'; // 替换为你的应用程序在 Play Store 的链接
  //   // window.location.href = linkToUrl;
  //   if (isIOS) {
  //     window.location.href = appUrlScheme; // 直接跳转应用程序（iOS）
  //     setTimeout(function () {
  //       window.location.href = linkToUrl;
  //     }, 1000);
  //     // appDownload();
  //     // openAppIOS();
  //   } else if (isAndroid) {
  //     window.location.href = appUrlScheme; // 跳转 Play Store（Android）
  //     setTimeout(function () {
  //       window.location.href = linkToUrl;
  //     }, 2000);
  //   } else {
  //     window.location.href = linkToUrl; // 跳转（非移动设备）
  //   }
  // }
  return (
    <div className="learnMore">
      <div className="content">
        <img src={LogoOrange} alt="" />
        <div className="textContent">
          <div className='bold'><FormattedMessage id='sunLifeMPF'></FormattedMessage></div>
          <div><FormattedMessage id='bestServeMPFMobileApp'></FormattedMessage></div>
        </div>
        <div className="btn">
          <button onClick={() => { handleButtonClick() }}><FormattedMessage id='learnMore'></FormattedMessage></button>
        </div>
      </div>
    </div>
  );
}

export default LearnMore;