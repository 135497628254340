import React from 'react';
import './notification.scss'
import {AlertIcon} from '../../../../utils/imgUrl'
import { FormattedMessage } from 'react-intl';

function Notification() {
  return (
    <div className="Notification">
        <div className="content"> 
            <img src={AlertIcon} alt="" />
            <span><FormattedMessage id="notification"></FormattedMessage></span>
        </div>
    </div>
  );
}

export default Notification;