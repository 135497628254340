import React,{useEffect,useCallback} from 'react';
import MyTabBar from '../common/myTabBar';
import { NavBar } from 'antd-mobile-v5';
import './moreService.scss'
import { FormattedMessage,useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import {
    EditProfileV2,
    eAdviceOption,
    MarketingPreference,

    MyPoliciesV2,
    PolicyDocuments,
    Coverage,
    Assets,

    MakeaClaim,
    ClaimsHistory,
    ClaimProcedures,

    PremiumAllocationV2,
    FundSwitchV2,
    FundHistory,
    InvestmentLinkedFundPrices,

    ValueWithdrawal,
    StandingInstruction,
    PayoutMethod,
    RequestStatusV2,
    BeneficiaryManage,
    PremiumPayment,

    OnlineTools,
    FindaForm,
    ExchangeRates,
    eAdviceService,
    InsuranceRelatedGlossary,
    PersonalInformationCollection,
    FAQ,
    PaymentMethods,

    MyCoupons,
    
    ContactUs,
    Feedback
} from '../../utils/imgUrl';


function MoreService() {
    const intl = useIntl();
    const setPageTitle = useCallback(() => {
      // 设置页面标题
      document.title = intl.formatMessage({ id: "PromptTitle" });
    }, [intl]);
    useEffect(() => {
      setPageTitle();
    }, [setPageTitle]);
    const List = [
        {
            pageName: 'Profile',
            key: 'Profile',
            tabs: [
                {
                    key: "EditProfile",
                    url: EditProfileV2,
                    handleName:'handleBrowser',
                    route: "/profile",
                    alt: ''
                },
                {
                    key: "eAdviceOption",
                    url: eAdviceOption,
                    handleName:'handleBrowser',
                    route: "/eadvice-option",
                    alt: ''
                },
                {
                    key: "MarketingPreference",
                    url: MarketingPreference,
                    handleName:'handleBrowser',
                    route: "/marketing-preference",
                    alt: ''
                },
            ],

        },
        {
            key: 'Policy',
            pageName: 'Policy',
            tabs: [{
                key: "MyPolicies",
                url: MyPoliciesV2,
                handleName:'handleBrowser',
                route: "/policy-list",
                alt: ''
            },
            {
                key: "PolicyDocuments",
                url: PolicyDocuments,
                handleName:'handleBrowser',
                route: "/document-types",
                alt: ''
            },
            {
                key: "Coverage",
                url: Coverage,
                handleName:'handleBrowser',
                route: "/coverages",
                alt: ''
            },
            {
                key: "Assets",
                url: Assets,
                handleName:'handleBrowser',
                route: "/assets",
                alt: ''
            }],
        },
        {
            key: 'Claims',
            pageName: 'claims',
            tabs: [{
                key: "MakeaClaim",
                url: MakeaClaim,
                handleName:'handleBrowser',
                route: "/make-claim",
                alt: ''
            },
            {
                key: "ClaimsHistory",
                url: ClaimsHistory,
                handleName:'handleBrowser',
                route: "/claim-history",
                alt: ''
            },
            {
                key: "ClaimProcedures",
                url: ClaimProcedures,
                handleName:'individual-insurance-claims',
                route: "/claims-procedure-list",
                alt: ''
            },

            ]
        },
        {
            key: 'Invest',
            pageName: 'Invest',
            tabs: [{
                key: "PremiumAllocation",
                url: PremiumAllocationV2,
                handleName:'handleBrowser',
                route: "/premium-allocation-policy-list",
                alt: ''
            },
            {
                key: "FundSwitch",
                url: FundSwitchV2,
                handleName:'handleBrowser',
                route: "/fund-switch-policy-list",
                alt: ''
            },
            {
                key: "FundHistory",
                url: FundHistory,
                handleName:'handleBrowser',
                route: "/fund-transaction",
                alt: ''
            },
            {
                key: "InvestmentLinkedFundPrices",
                url: InvestmentLinkedFundPrices,
                handleName:'handleBrowser',
                route: "/fund-product",
                alt: ''
            }
            ]
        },
        {
            key: 'PolicyServices',
            pageName: 'PolicyServices',
            tabs: [
            {
                key: "ValueWithdrawal",
                url: ValueWithdrawal,
                handleName:'handleBrowser',
                "route": "/value-withdrawal",
                alt: ''
            },
            {
                key: "StandingInstruction",
                url: StandingInstruction,
                handleName:'handleBrowser',
                "route": "/standing-instruction",
                alt: ''
            },
            {
                key: "PayoutMethod",
                url: PayoutMethod,
                handleName:'handleBrowser',
                "route": "/payout-method",
                alt: ''
            },
            {
                key: "RequestStatus",
                url: RequestStatusV2,
                handleName:'handleBrowser',
                "route": "/policy-service-request",
                alt: ''
            },
//--------------------------------------------------------------------
            // {
            //     key: "BeneficiaryManage",
            //     url: BeneficiaryManage,
            //     handleName:'handleBrowser',
            //     "route": "/policy-detail",
            //     alt: ''
            // },
//--------------------------------------------------------------------
            {
                key: "PremiumPayment",
                url: PremiumPayment,
                handleName:'handleBrowser',
                "route": "/policy-detail",
                alt: ''
            },
            ]
        },
        {
            key: 'UsefulResources',
            pageName: 'UsefulResources',
            tabs: [{
                key: "OnlineTools",
                url: OnlineTools,
                module:'about-us',
                handleName:'tools-resources',
                alt: ''
            },
            {
                key: "FindaForm",
                url: FindaForm,
                module:'client-support',
                handleName:'find-a-form',
                alt: ''
            },
            {
                key: "ExchangeRates",
                url: ExchangeRates,
                module:'client-support',
                handleName:'exchange-rates',
                alt: ''
            },
            {
                key: "eAdviceService",
                url: eAdviceService,
                module:'client-support',
                handleName:'eadvice-service',
                alt: ''
            },
            {
                key: "InsuranceRelatedGlossary",
                url: InsuranceRelatedGlossary,
                module:'about-us',
                route: "/financial-glossary",
                handleName:'handleBrowser',
                alt: ''
            },
            {
                key: "PersonalInformationCollection",
                url: PersonalInformationCollection,
                module:'client-support',
                handleName:'personal-information-collection',
                alt: ''
            },
            {
                key: "FAQ",
                url: FAQ,
                module:'client-support',
                handleName:'faq',
                alt: ''
            },
            {
                key: "PaymentMethods",
                url: PaymentMethods,
                module:'client-support',
                handleName:'payment-method',
                alt: ''
            },
            ]
        },
        {
            key: 'Rewards',
            pageName: 'Rewards',
            tabs: [{
                key: "MyCoupons",
                url: MyCoupons,
                handleName:'handleBrowser',
                "route": "/my-coupon",
                alt: ''
            },
            ]
        },
        {
            key: 'ClientServices',
            pageName: 'ClientServices',
            tabs: [{
                key: "ContactUs",
                url: ContactUs,
                handleName:'handleBrowser',
                "route": "/contact-find-us",
                alt: ''
            },
            {
                key: "Feedback",
                url: Feedback,
                handleName:'handleBrowser',
                "route": "/feedback",
                alt: ''
            },
            ]
        }
    ]
    const navigate  = useNavigate();
    const backHandle = () => {
        navigate('/')
    }
    return (

        <div className="moreService">
            <div className="Page">
                <NavBar back={<FormattedMessage id='back'></FormattedMessage>} onBack={backHandle}>
                    <FormattedMessage id='moreServicesTitle'></FormattedMessage>
                </NavBar>
                <div className='moreServicesBox'>
                    {
                        List.map((item, index) => (
                            <div className='moreSerItem' key={item.key}>
                                <div className='moreSerTitle'><FormattedMessage id={item.key}></FormattedMessage></div>
                                <MyTabBar
                                    tabs={item.tabs}
                                    pageName={item.pageName}
                                />
                            </div>
                        ))
                    }
                </div>

            </div>

        </div>
    );
}

export default MoreService;