import React from 'react';
import './latestNews.scss'
import {LatestNews1,LatestNews2,LatestNews3,LatestNews4} from '../../../../utils/imgUrl'
import ImgDisplay from '../../../common/imgDisplay';
import { FormattedMessage } from 'react-intl';
const newsList=[
    {
        title: 'PD1T',
        url: LatestNews1,
        description: 'PD1D',
        handleName:'latestNews'
      },
      {
        title: 'PD2T',
        url: LatestNews2,
        description: 'PD2D',
        handleName:'latestNews'
      },
      {
        title: 'PD3T',
        url: LatestNews3,
        description: 'PD3D',
        handleName:'latestNews'
      },
      {
        title: 'PD4T',
        url: LatestNews4,
        description: 'PD4D',
        handleName:'latestNews'
      },
]
function LatestNews() {
  return (
    <div className="LatestNews">
        <div className="title"><FormattedMessage id='latestNewsList.section'></FormattedMessage></div>
        <ImgDisplay
            imgList={newsList}
            pageName='latestNewsList'
            hasDescription={false}
        />
    </div>
  );
}

export default LatestNews;