import React from 'react';
import './index.scss';
import ImgFrame from './imgFrame.jsx'
import Notification from './notification.jsx'
import QuickLink from './quicklink.jsx';
import LearnMore from './learnMore.jsx';
import DesktopBanner from './desktopBanner.jsx';
import LatestNews from './latestNews.jsx';
import Ensure from './ensure.jsx';



function MainContent() {
  return (
    <div className="MainContent">
        <ImgFrame/>
        <Notification/>
        <QuickLink/>
        <LearnMore/>
        <DesktopBanner/>
        <LatestNews/>
        <Ensure/>
    </div>
  );
}

export default MainContent;