import React from 'react'
import { Tabs } from 'antd-mobile-v5';
import './ensure.scss'
import ImgDisplay from '../../../common/imgDisplay';
import { FormattedMessage } from 'react-intl';
import {
  SavingsLife1,SavingsLife2,SavingsLife3,SavingsLife4,SavingsLife5,SavingsLife6,SavingsLife7,
  MedicalCriticalIllness1,MedicalCriticalIllness2,MedicalCriticalIllness3,MedicalCriticalIllness4,MedicalCriticalIllness5,MedicalCriticalIllness6,
  AccidentDisability1,AccidentDisability2,AccidentDisability3,
  UniversalLife1,UniversalLife2,UniversalLife3
} from '../../../../utils/imgUrl'
const tabItems = [
  { 
    key: 'savings-and-life', 
    list:[
      {
        title: 'PD1T',
        url: SavingsLife1,
        description: 'PD1D',
        handleName:'stellar-multi-currency-plan'
      },
      {
        title: 'PD2T',
        url: SavingsLife2,
        description: 'PD2D',
        handleName:'sunjoy-global'
      },
      {
        title: 'PD3T',
        url: SavingsLife3,
        description: 'PD3D',
        handleName:'sungift-global'
      },
      {
        title: 'PD4T',
        url: SavingsLife4,
        description: 'PD4D',
        handleName:'sunprotect'
      },
      {
        title: 'PD5T',
        url: SavingsLife5,
        description: 'PD5D',
        handleName:'sungift'
      },
      {
        title: 'PD6T',
        url: SavingsLife6,
        description: 'PD6D',
        handleName:'sunjoy'
      },
      {
        title: 'PD7T',
        url: SavingsLife7,
        description: 'PD7D',
        handleName:'LearnMore'
      }
    ]
  },
  { 
    key: 'medical-and-critical-illness', 
    title: '医疗及危疾',
    list:[
      {
        title: 'PD1T',
        url: MedicalCriticalIllness1,
        description: 'PD1D',
        section:'voluntary-health-insurance-scheme',
        handleName:'wehealth-prestige'
      },
      {
        title: 'PD2T',
        url: MedicalCriticalIllness2,
        description: 'PD2D',
        section:'voluntary-health-insurance-scheme',
        handleName:'wehealth-preferred'
      },
      {
        title: 'PD3T',
        url: MedicalCriticalIllness3,
        description: 'PD3D',
        section:'voluntary-health-insurance-scheme',
        handleName:'wehealth-plus'
      },
      {
        title: 'PD4T',
        url: MedicalCriticalIllness4,
        description: 'PD4D',
        section:'voluntary-health-insurance-scheme',
        handleName:'wehealth'
      },
      {
        title: 'PD5T',
        url: MedicalCriticalIllness5,
        description: 'PD5D',
        handleName:'sunhealth-ultracare-and-sunhealth-maxicare-video'
      },
      {
        title: 'PD6T',
        url: MedicalCriticalIllness6,
        description: 'PD6D',
        handleName:'LearnMore'
      },
    ] 
  },
  {
    key: 'accident-and-disability', 
    list:[
      {
        title: 'PD1T',
        url: AccidentDisability1,
        description: 'PD1D',
        handleName:'suncare-accident-protection-plan'
      },
      {
        title: 'PD2T',
        url: AccidentDisability2,
        description: 'PD2D',
        handleName:'suncover-free-personal-accident-protection-plan'
      },
      {
        title: 'PD3T',
        url: AccidentDisability3,
        description: 'PD3D',
        handleName:'LearnMore'
      },
    ] 
  },
  {
    key: 'universal-life', 
    list:[
      {
        title: 'PD1T',
        url: UniversalLife1,
        description: 'PD1D',
        handleName:'bright-ul-wealth-builder'
      },
      {
        title: 'PD2T',
        url: UniversalLife2,
        description: 'PD2D',
        handleName:'sunrise-universal-life.html'
      },
      {
        title: 'PD3T',
        url: UniversalLife3,
        description: 'PD3D',
        handleName:'LearnMore'
      },
    ] 
  },
]
export default () => {
  return (
    <div className='ensure'>
      <div className="title"><FormattedMessage id="insurance"></FormattedMessage></div>
      <Tabs className='Insurance'>
        {tabItems.map(item => (
          <Tabs.Tab 
            title={<FormattedMessage id={`${item.key}.section`}></FormattedMessage>} 
            key={item.key}
          >
            <ImgDisplay
              imgList={item.list}
              pageName={item.key}
              hasDescription={true}
            />
          </Tabs.Tab>
        ))}
      </Tabs>
    </div>
  )
}