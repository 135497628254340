import React, { useEffect, useState } from 'react';
import { useParams  } from 'react-router-dom';
import { useIntl } from 'react-intl';
import HomePage from './homepage/index.jsx';
import Browser from './browserPage.jsx';

const RedirectPage = ({switchLocale}) => {

  const intl = useIntl();
  const currentLocale = intl.locale;
  const locateUrl2={
    en:"en",
    sc:"zh-cn",
    tc:"zh-hk"
  }

  const currentHref=window.location.href.split('/')
  const lg=currentHref[currentHref.length-1]

  const [isWechatBrowser, setIsWechatBrowser] = useState(true);
  const { pageName,route } = useParams();

  useEffect(() => {
    // 获取当前浏览器的 User Agent
    const userAgent = navigator.userAgent.toLowerCase();
    // 检测是否在微信浏览器中
    const isWechat = /micromessenger/.test(userAgent);
    setIsWechatBrowser(isWechat);
  }, [isWechatBrowser]);

  //learnMore
  //TarBar
  useEffect(() => {
    // 如果不在微信浏览器中，根据传参跳转至新的地址
    if (!isWechatBrowser && pageName==='TarBar') {
      window.location.href = `https://mysunlife.sunlife.com.hk/ClientAppWeb/#/login?redirect_url=${route}&locale=${locateUrl2[lg]}`;
    }else if(!isWechatBrowser && pageName==='Login'){
      // if(route==="notification"||route==="dashboard"){
      //   window.location.href =(route==='notification')
      //   ?`https://mysunlife.sunlife.com.hk/ClientAppWeb/#/login?redirect_url=/notification&locale=${locateUrl2[lg]}`
      //   :`https://mysunlife.sunlife.com.hk/ClientAppWeb/#/login?redirect_url=/dashboard&locale=${locateUrl2[lg]}`
      // }
      if(route==="notification"){
        window.location.href=`https://mysunlife.sunlife.com.hk/ClientAppWeb/#/login?redirect_url=/notification&locale=${locateUrl2[currentLocale]}`
      }else if(route==="dashboard"){
        window.location.href=`https://mysunlife.sunlife.com.hk/ClientAppWeb/#/login?redirect_url=/dashboard&locale=${locateUrl2[currentLocale]}`
      }else if(route==='sun-wallet'){
        window.location.href=`https://mysunlife.sunlife.com.hk/ClientAppWeb/#/login?redirect_url=/sun-wallet&locale=${locateUrl2[currentLocale]}`
      }else{
        window.location.href = `https://mysunlife.sunlife.com.hk/ClientAppWeb/#/login?locale=${locateUrl2[lg]}`;
      }
      
    }else if(!isWechatBrowser && pageName==='learnMore'){
      const linkToUrl = locateUrl2[lg]==="en"
      ?"https://online.cspension.com.hk/hkpensionweb/#/login?source=corporateHK&vgnLocale=en_CA&WT.ac=zh-hk:web:slf_internal:hp_signin_modal:rainbowmpf"
      :"https://online.cspension.com.hk/hkpensionweb/#/login?source=corporateHK&vgnLocale=zh_TW&WT.ac=zh-hk:web:slf_internal:hp_signin_modal:rainbowmpf"; // 浏览器自动跳转的url
      window.location.href=linkToUrl
    }
  }, [isWechatBrowser,pageName,route]);

  return (
    <div>
      {isWechatBrowser?<Browser />:null}
      {/* {(!isWechatBrowser && pageName==='learnMore') ? 
      <HomePage 
        switchLocale={switchLocale}
      /> : null} */}
    </div>
  );
};

export default RedirectPage;
